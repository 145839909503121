// Roboto

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../../fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../../../fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Italic";
  src: url("../../../fonts/Roboto/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../../../fonts/Roboto/Roboto-Thin.ttf");
}

// Roboto Mono

@font-face {
  font-family: "RobotoMono-Thin";
  src: url("../../../fonts/RobotoMono/RobotoMono-Thin.ttf");
}

@font-face {
  font-family: "RobotoMono-Light";
  src: url("../../../fonts/RobotoMono/RobotoMono-Light.ttf");
}
@font-face {
  font-family: "RobotoMono-Regular";
  src: url("../../../fonts/RobotoMono/RobotoMono-Regular.ttf");
}
@font-face {
  font-family: "RobotoMono-Bold";
  src: url("../../../fonts/RobotoMono/RobotoMono-Bold.ttf");
}

// Lobster 2

@font-face {
  font-family: "Lobster2-Regular";
  src: url("../../../fonts/LobsterTwo/LobsterTwo-Regular.ttf");
}

// Playball

@font-face {
  font-family: "Playball-Regular";
  src: url("../../../fonts/Playball/Playball-Regular.ttf");
}

// Katibeh

@font-face {
  font-family: "Katibeh-Regular";
  src: url("../../../fonts/Katibeh/Katibeh-Regular.ttf");
}
