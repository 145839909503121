*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.red {
  color: #d34a38;
}

.blue {
  color: #6796fa;
}

.green {
  color: #d4b57c;
}
