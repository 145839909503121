.__sweven_contact {
  &-main {
    min-height: 50rem;
    min-height: 50rem;
    background-image: url("../../images/contact-us.png"),
      url("../../images/linesRed.png");
    background-size: contain, auto 40%;
    background-repeat: no-repeat;
    background-position: center right, left 30rem;

    &--description {
      padding: 12rem 15rem 12rem 5rem;
      &__heading {
        font-size: 4rem;
        font-family: "Roboto-Bold";
        line-height: 1;

        span {
          display: inline-block;
          line-height: 1 !important;
        }
      }

      &__signUp {
        margin: 2rem 0;
        a {
          font-size: 1.5rem;
          font-family: "Roboto-Bold";
          color: $textColor-light !important;
          background: linear-gradient(to right, #4da0b0, #d39d38);
          box-shadow: 0 0 0.8rem #734b6d;
          border: none;
          outline: none;
          text-align: center;
          padding: 1rem 2rem;
          cursor: pointer;
          border-radius: 5rem;
          animation: buttonAnimate ease-in-out 2s infinite;
          transition: all ease-in-out 0.25s !important;

          &:hover {
            transform: scale(1.2) !important;
          }

          @keyframes buttonAnimate {
            0% {
              box-shadow: 0 0 0.8rem #734b6d;
            }
            50% {
              box-shadow: 0 0 1.5rem #734b6d;
            }

            100% {
              box-shadow: 0 0 0.8rem #734b6d;
            }
          }
        }
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }

  &-form {
    display: flex;
    flex-direction: column;
    padding: 5rem;
    box-shadow: 1rem 1rem 2rem #ccc, -1rem -1rem 2rem #ccc;

    &--heading {
      font-size: 4rem;
      font-family: "Roboto-Bold";
      color: $textColor-red;
    }

    form {
      display: flex;
      flex-direction: column;
      width: fit-content;

      input {
        width: 30rem;
        border: none;
        border-bottom: 0.1rem solid #ccc;
        margin: 1rem;
        outline: none;
      }

      textarea {
        width: 30rem;
        border: none;
        border-bottom: 0.1rem solid #ccc;
        margin: 1rem;
        height: 10rem;
        resize: none;
        outline: none;
      }

      button {
        width: 10rem;
        margin: 1rem;
        border: none;
        outline: none;
        padding: 1rem 2rem;
        cursor: pointer;
        font-family: "Roboto-Bold";
        background-color: $textColor-red;
        color: $textColor-light;
        border: 0.1rem solid $textColor-red;
        transition: all ease-in 0.25s;

        &:hover {
          background-color: $textColor-light;
          color: $textColor-red;
        }
      }
    }
  }

  &-information {
    display: flex;
    flex-direction: column;
    padding: 8rem 5rem;
    background-color: #fafafa6c;
    box-shadow: 1rem 1rem 2rem #ccc, -1rem -1rem 2rem #ccc;
    // background: linear-gradient(to right, #4da0b0, #d39d38);
    background-color: $textColor-red;
    margin-left: -3rem;

    &--heading {
      font-size: 2rem;
      font-family: "RobotoMono-Bold";
    }

    &--lists {
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        flex-direction: column;

        &_title {
          font-size: 2rem;
          color: $textColor-light;
          font-family: "RobotoMono-Bold";
        }

        &_detail {
          display: flex;
          flex-direction: row !important;
          color: $textColor-light;

          &-icon {
            margin-right: 2rem;
          }
        }
      }
    }

    &--social {
      display: flex;
      flex-direction: row;

      &__link {
        margin-right: 1rem;
        font-size: 3rem;
      }
    }
  }
}
