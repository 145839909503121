.__sweven_sectionHeader {
  text-align: center;
  font-size: 4rem;
  font-family: "Roboto-Bold";
  color: $textColor-red;
  position: relative;
  margin: 5rem 0 2rem 0;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translate(-50%, -100%);
    height: 0.3rem;
    width: 6rem;
    background-image: linear-gradient(
      to right,
      $textColor-red,
      $textColor-light
    );
  }
}
