.__sweven_services {
  &-main {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-image: url("../../images/lines.png"), url("../../images/SEO.png");
    background-repeat: no-repeat;
    background-position: 0 90%, right center;
    background-size: 15% auto, contain;

    &--description {
      padding: 17rem 15rem 12rem 5rem;
      &__heading {
        div {
          display: inline-block;
          font-size: 5rem;
          font-family: "Roboto-Bold";
          color: $textColor-red;
          line-height: 1;
          background: linear-gradient(to right, #5150ad, #e6c573);
          text-transform: uppercase;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &__body {
        margin: 3rem 0;
        font-size: 1.5rem;
        font-family: "RobotoMono-Regular";
        max-width: 40rem;
        text-align: justify;
        // color: $textColor-dark2;
      }

      &__button {
        a {
          background: linear-gradient(to right, #4da0b0, #d39d38);
          box-shadow: 0 0 0.8rem #734b6d;
          border: none;
          outline: none;
          text-align: center;
          padding: 1rem 2rem;
          cursor: pointer;
          border-radius: 5rem;
          animation: buttonAnimate ease-in-out 2s infinite;
          transition: all ease-in-out 0.5s !important;
          display: inline-block;
          font-size: 1.75rem;
          font-family: "Roboto-Bold";
          color: $textColor-light;

          @keyframes buttonAnimate {
            0% {
              box-shadow: 0 0 0.8rem #734b6d;
            }
            50% {
              box-shadow: 0 0 1.5rem #734b6d;
            }

            100% {
              box-shadow: 0 0 0.8rem #734b6d;
            }
          }

          &:hover {
            transform: scale(1.2) !important;
            color: $textColor-light;
          }
        }
      }
    }
  }

  &-list {
    &--wrapper {
      display: flex;
      flex-direction: column;

      &__service {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 5rem;

        &:nth-child(even) {
          flex-direction: row-reverse !important;
        }

        .rodal-dialog {
          max-height: 90vh !important;
          max-width: 90vw !important;
          width: 80vw !important;
          overflow-y: scroll !important;
          overflow-x: hidden;
          padding: 0 !important;
        }

        &_image {
          img {
            height: 40rem;
          }
        }

        &_description {
          max-width: 40vw;
          &-title {
            font-size: 2.5rem;
            font-family: "RobotoMono-Bold";
            color: $textColor-red;
          }

          &-body {
            font-size: 1.5rem;
            color: $textColor-dark2;
            opacity: 0.7;
          }

          &-lists {
            display: flex;
            flex-direction: column;
            padding: 1rem 3rem;
            color: $textColor-red;
            font-size: 1.5rem;
            font-family: "RobotoMono-Regular";
          }

          &-button {
            font-size: 1.75rem;
            color: rgb(165, 107, 216);
            cursor: pointer;
            display: inline-block;
          }
        }

        &_modal {
          &-header {
            margin: 1rem 0 2rem 0;
          }
          &-quote {
            width: 100%;
            padding: 2rem 1rem;
            background-color: #92d050 !important;
            margin: 0 !important;
            font-family: "RobotoMono-Bold";

            &--text {
              font-size: 2rem;
              color: #0275b0;
            }

            &--person {
              font-size: 1.25rem;
              color: #2d9abe;
            }
          }

          &-description {
            font-size: 1.5rem;
            font-family: "Roboto-Regular";
            padding: 2rem;
            color: #0275b0;
          }

          &-cards {
            display: flex;
            flex-direction: row;
            justify-content: center;
            justify-content: center;
            flex-wrap: wrap;
            &--card {
              text-align: left;
              box-shadow: 0rem 0rem 0.2rem #ccc;
              margin: 1rem;
              padding: 1rem;
              width: 25rem;
              cursor: pointer;
              background: #4affd7 !important;

              &:hover {
                box-shadow: 0 0 0.5rem #ccc;
              }

              &__title {
                color: #0275b0;
                font-size: 2rem;
                font-family: "Roboto-Bold";
              }

              &__lists {
                padding: 1rem;
                &_item {
                  color: #2d9abe;
                }
              }
            }
          }

          &-extra {
            display: flex;
            flex-direction: column;

            &--title {
              padding: 1rem 5rem;
              font-size: 3rem;
              color: $textColor-green;
              font-family: "RobotoMono-Bold";
              text-align: center;
            }

            &--item {
              margin: 0;
            }

            &--description {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              flex-wrap: wrap;
              padding: 1rem 5rem;
              font-family: "Roboto-Regular";
              font-size: 1.5rem;

              div {
                max-width: 50vw;
              }

              img {
                height: 15rem;
                margin: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
