.__sweven_notices {
  min-height: 70rem;
  padding: 10rem 5rem 10rem 5rem;

  &-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    &--item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      border-radius: 1rem;
      box-shadow: 0.1rem 0.1rem 1rem #ccc;
      width: 100%;

      &__title {
        font-size: 3rem;
        font-family: "RobotoMono-Bold";
        word-spacing: -5px !important;
      }

      &__meta {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        color: rgb(160, 156, 156);
        margin: 0.5rem 0;

        &_person {
          margin-right: 2rem;
        }
      }

      &__body {
        font-size: 1.5rem;
        font-family: "Roboto-Regular";
      }

      &__link {
        color: blueviolet;
        text-decoration: underline;
        margin-top: 1rem;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
}
