.__sweven {
  &_footer {
    background: #3b92ca;
    color: $textColor-light;
    width: 100%;
    z-index: 9;
    padding: 2rem 1rem;
    overflow: hidden;
    &--meta {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      font-size: 1.25rem;
      &-logo {
        margin: 1rem 0;
        img {
          width: 2rem;
        }
      }
    }

    &--description {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;

      &-write,
      &-visit,
      &-call,
      &-follow,
      &-lists {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;

        h2 {
          font-size: 2rem !important;
          font-family: "RobotoMono-Bold" !important;
          color: $textColor-light;
        }

        &_label {
          margin: 1rem 0;
          font-size: 1.25rem;
          font-family: "RobotoMono-Regular";
          color: $textColor-light;
        }

        &_link {
          font-size: 1.25rem;
          font-family: "Roboto-Regular";
          color: $textColor-light;
          margin: 0.25rem 0 1rem 0;

          &:hover {
            color: $textColor-light;
            opacity: 0.7;
          }
        }
      }

      &-follow {
        div {
          a {
            margin: 0 1rem 0 0;
          }
        }
      }

      &-lists {
        div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center !important;
          font-family: serif;
          font-weight: bold;
          font-size: 1.5rem;
          img {
            display: inline-block;
            height: 3rem;
            width: 2rem;
            margin: 0 1rem 0 0;
          }
        }

        span {
          margin: 1rem 0;
          max-width: 20rem;
          font-size: 1.25rem;
          font-family: "Roboto-Regular";
        }
      }
    }
  }
}
